import { MoonIcon, SearchIcon, SunIcon, XIcon } from "@heroicons/react/outline";
import {
  LightningBoltIcon,
  MenuIcon,
  ViewGridIcon,
  LockOpenIcon,
} from "@heroicons/react/solid";
import { useTheme } from "next-themes";
import Head from "next/head";
import Link from "next/link";
import { NextSeo } from "next-seo";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  SVGProps,
  useState,
  forwardRef,
} from "react";
import { useAuth } from "../../hooks/auth";
import { Popover, Transition, Disclosure, Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import { useLoaded } from "../../hooks/loaded";
import ErrorCallout from "../callouts/error";
import { Toaster, toast } from "react-hot-toast";
import classNames from "../../util/classNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthUser, DBUser } from "../../model/user";
import CreatePrepCenterModal from "../modal/createPrepCenter";
import Image from "next/image";
import Search from "../search";

type NavItem = {
  name: string;
  href: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

const nav: NavItem[] = [
  { name: "Recent", href: "/" },
  { name: "Map", href: "/search" },
];

type FooterItem = {
  name: string;
  href: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

const footer: FooterItem[] = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/prepcenters/",
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <FontAwesomeIcon icon={["fab", "facebook"]} className="text-xl" />
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/prepcenters/",
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <FontAwesomeIcon icon={["fab", "instagram"]} className="text-xl" />
    ),
  },
  {
    name: "Twitter",
    href: "https://twitter.com/prepcenters",
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <FontAwesomeIcon icon={["fab", "twitter"]} className="text-xl" />
    ),
  },
  {
    name: "Discord",
    href: "https://discord.gg/amazonfba",
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <FontAwesomeIcon icon={["fab", "discord"]} className="text-xl" />
    ),
  },
];

const getInitials = (dbUser: DBUser): string => {
  if (dbUser.first_name && dbUser.last_name) {
    return (
      dbUser.first_name.charAt(0).toUpperCase() +
      dbUser.last_name.charAt(0).toUpperCase()
    );
  }

  return dbUser.name?.slice(0, 2).toUpperCase() ?? "PC";
};

export default function MainLayout({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) {
  const {
    authUser,
    dbUser,
    prepCenter,
    support,
    logout,
    userError,
    getOrCreateUser,
  } = useAuth();
  const loaded = useLoaded();
  const { resolvedTheme, theme, setTheme } = useTheme();
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (authUser && authUser.theme) {
      setTheme(authUser.theme);
    }
  }, [authUser, setTheme]);

  const updateTheme = useCallback(async () => {
    if (authUser) {
      const newTheme = theme === "light" ? "dark" : "light";
      setTheme(newTheme);
    }
  }, [setTheme, authUser, theme]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error: ${error.message}`);
      }
    }
  }, [logout]);

  return (
    <>
      <NextSeo
        title={
          title
            ? `${title} | PrepCenter`
            : "PrepCenter | Find an Amazon FBA Prep Center"
        }
      />

      <div className="min-h-screen">
        <Disclosure as="nav" className="bg-vendrive-base">
          {({ open, close }) => (
            <>
              {dbUser?.is_admin && (
                <div className="relative bg-amber-600 shadow-sm mb-2">
                  <div className="max-w-7xl mx-auto py-2 px-2 sm:px-4 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center px-2 lg:px-0">
                        <span className="flex p-2 rounded-lg bg-amber-700">
                          <LockOpenIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </span>

                        <p className="ml-3 font-medium text-white truncate">
                          <span>Admin access granted.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative h-16 flex items-center justify-between gap-2">
                  <div className="px-2 flex items-center lg:px-0">
                    <div className="flex-shrink-0">
                      <Link href="/" passHref>
                        <a
                          onClick={() => {
                            close();
                          }}
                        >
                          <div className="relative block w-40">
                            <Image
                              layout="responsive"
                              width={544.05}
                              height={95.48}
                              src="/images/logo-light.svg"
                              alt="PrepCenter"
                            />
                          </div>
                        </a>
                      </Link>
                    </div>
                    <div className="hidden lg:block lg:ml-10">
                      <div className="flex space-x-4">
                        {nav.map((item) => (
                          <Link key={item.name} href={item.href} passHref>
                            <a
                              onClick={() => {
                                close();
                              }}
                              className={classNames(
                                router.pathname === item.href
                                  ? "bg-vendrive-800 text-white"
                                  : "text-white hover:bg-vendrive-600 hover:bg-opacity-75",
                                "rounded-md px-4 py-2 text-base font-medium"
                              )}
                              aria-current={
                                router.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </a>
                          </Link>
                        ))}

                        <a
                          href="https://goaura.com/?utm_source=prepcenter"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            close();
                          }}
                          className="rounded-md px-4 py-2 text-base font-medium text-white hover:bg-vendrive-600 hover:bg-opacity-75"
                        >
                          Repricer
                        </a>
                      </div>
                    </div>
                  </div>

                  <Search />

                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-vendrive-base p-2 rounded-md inline-flex items-center justify-center text-vendrive-200 hover:text-white hover:bg-vendrive-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-vendrive-base focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block lg:ml-4">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      {authUser && dbUser ? (
                        <div className="hidden md:flex md:items-center md:space-x-6">
                          {prepCenter && (
                            <Link href="/manage">
                              <a
                                onClick={() => {
                                  close();
                                }}
                                className="text-base font-medium text-white hover:text-gray-300"
                              >
                                Manage prep center
                              </a>
                            </Link>
                          )}

                          {(!prepCenter || dbUser?.is_admin) && (
                            <button
                              onClick={() => setModalOpen(true)}
                              className="text-base font-medium text-white hover:text-gray-300"
                            >
                              Add a prep center
                            </button>
                          )}

                          <Menu
                            as="div"
                            className="ml-3 relative flex-shrink-0"
                          >
                            <div>
                              <Menu.Button className="bg-vendrive-base rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-vendrive-base focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-full text-white bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70">
                                  <span className="font-medium leading-none">
                                    {getInitials(dbUser)}
                                  </span>
                                </span>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute z-20 origin-top-right shadow-lg bg-white rounded-md text-sm mt-2 w-72 right-0 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <span className="p-5 flex w-full justify-between items-center border-b border-neutral-100">
                                  <span className="flex min-w-0 items-center justify-between space-x-3">
                                    <div className="rounded-full bg-neutral-300 h-9 w-9 flex justify-center items-center flex-shrink-0">
                                      <p className="text-white text-xs">
                                        {getInitials(dbUser)}
                                      </p>
                                    </div>
                                    <span className="flex-1 flex flex-col min-w-0">
                                      <span className="text-gray-900 text-sm font-medium truncate">
                                        {dbUser?.getName()}
                                      </span>
                                      <span className="text-gray-500 text-sm truncate">
                                        {authUser.email}
                                      </span>
                                    </span>
                                  </span>
                                </span>

                                <div className="py-2">
                                  <Link href="/settings" passHref>
                                    <Menu.Item>
                                      <a
                                        onClick={() => {
                                          close();
                                        }}
                                        className="block py-2 px-5 text-sm text-gray-700 hover:bg-gray-100"
                                      >
                                        Settings
                                      </a>
                                    </Menu.Item>
                                  </Link>
                                  <Menu.Item>
                                    <button
                                      className="block py-2 px-5 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                      onClick={handleLogout}
                                    >
                                      <p>Sign out</p>
                                    </button>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      ) : (
                        <>
                          {/* Unauthenticated section */}
                          <div className="hidden md:flex md:items-center md:space-x-6">
                            <Link href="/signin">
                              <a
                                onClick={() => {
                                  close();
                                }}
                                className="text-base font-medium text-white hover:text-gray-300"
                              >
                                Sign in
                              </a>
                            </Link>
                            <Link href="/signup">
                              <a
                                onClick={() => {
                                  close();
                                }}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70"
                              >
                                Sign up
                              </a>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {nav.map((item) => (
                    <Link key={item.name} href={item.href} passHref>
                      <Disclosure.Button
                        as="a"
                        onClick={() => {
                          close();
                        }}
                        className={classNames(
                          router.pathname === item.href
                            ? "bg-vendrive-800 text-white"
                            : "text-white hover:bg-vendrive-600 hover:bg-opacity-75",
                          "block rounded-md py-2 px-3 text-base font-medium"
                        )}
                        aria-current={
                          router.pathname === item.href ? "page" : undefined
                        }
                      >
                        {item.name}
                      </Disclosure.Button>
                    </Link>
                  ))}

                  <a
                    href="https://goaura.com/?utm_source=prepcenter"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      close();
                    }}
                    className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-vendrive-600 hover:bg-opacity-75"
                  >
                    Repricer
                  </a>
                </div>

                {/* Profile section */}
                {authUser && dbUser ? (
                  <div className="pt-8 pb-3 border-t border-vendrive-600">
                    <div className="px-5 flex items-center">
                      <div className="flex-shrink-0">
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full text-white bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70">
                          <span className="font-medium leading-none">
                            {getInitials(dbUser)}
                          </span>
                        </span>
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {dbUser.getName()}
                        </div>
                        <div className="text-sm font-medium text-vendrive-300">
                          {authUser.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      {prepCenter && (
                        <Link href="/manage" passHref>
                          <Disclosure.Button
                            as="a"
                            onClick={() => {
                              close();
                            }}
                            className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-vendrive-500 hover:bg-opacity-75"
                          >
                            Manage prep center
                          </Disclosure.Button>
                        </Link>
                      )}

                      {(!prepCenter || dbUser?.is_admin) && (
                        <Disclosure.Button
                          as="button"
                          onClick={() => {
                            setModalOpen(true);
                            close();
                          }}
                          className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-vendrive-500 hover:bg-opacity-75 w-full text-left"
                        >
                          Add a prep center
                        </Disclosure.Button>
                      )}

                      <Link href="/settings" passHref>
                        <Disclosure.Button
                          as="a"
                          onClick={() => {
                            close();
                          }}
                          className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-vendrive-500 hover:bg-opacity-75"
                        >
                          Settings
                        </Disclosure.Button>
                      </Link>

                      <Disclosure.Button
                        as="button"
                        onClick={handleLogout}
                        className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-vendrive-500 hover:bg-opacity-75 w-full text-left"
                      >
                        Sign out
                      </Disclosure.Button>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Unauthenticated section */}
                    <div className="mt-2 px-2 pt-8 border-t border-vendrive-600">
                      <Link href="/signup">
                        <a
                          onClick={() => {
                            close();
                          }}
                          className="block text-center w-full py-3 px-4 rounded-md shadow text-white font-medium bg-vendrive-500 bg-opacity-60 hover:bg-opacity-70"
                        >
                          Sign up
                        </a>
                      </Link>
                    </div>
                    <div className="pt-4 pb-6">
                      <p className="text-center text-base font-medium text-white">
                        Existing user?{" "}
                        <Link href="/signin">
                          <a
                            onClick={() => {
                              close();
                            }}
                            className="text-vendrive-200 hover:underline"
                          >
                            Sign in
                          </a>
                        </Link>
                      </p>
                    </div>
                  </>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {userError ? (
          <div className="my-10">
            <ErrorCallout subTitle={userError}></ErrorCallout>
          </div>
        ) : (
          children
        )}

        <footer>
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div className="flex justify-center space-x-6 md:order-2">
              {footer.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <div className="mt-8 md:mt-0 md:order-1">
              <p className="text-center text-base text-gray-400">
                &copy; {new Date().getFullYear()} PrepCenter. All rights
                reserved.
              </p>
            </div>
          </div>
        </footer>

        <div>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName="mb-14"
            toastOptions={{
              className:
                "text-sm bg-white dark:bg-neutral-base text-neutral-900 dark:text-white border border-solid border-neutral-100 dark:border-neutral-600",
              duration: 3200,
            }}
          />
        </div>

        <CreatePrepCenterModal
          open={modalOpen}
          setOpen={setModalOpen}
          onCreate={(prepCenter) => {
            getOrCreateUser(true);
            if (dbUser?.is_admin && prepCenter) {
              router.push(`/manage/${prepCenter?.id}`);
            }
          }}
        />
      </div>
    </>
  );
}
